import React from 'react';
import Layout from '../components/Layout';
import Markdown from '../components/utils/md/Markdown';

const IndexPage: React.FC = () => {
	const text = `С 1-го уровня у вас есть особый опыт в изучении, выслеживании, охоте и даже общении с определённым типом врагов. 
Выберите тип заклятого врага: аберрации, бестии, великаны, драконы, жижи, животные, конструкции, небожители, нежить, растения, монстры, феи или элементали. Как вариант, можете вместо этого выбрать две расы гуманоидов (например, гноллов и орков). 
У вас [преимущество](advantage) к проверкам [Мудрости (Выживание)](skill=survival) при выслеживании заклятых врагов, а также к проверкам [Интеллекта](a=intelligence), чтобы вспомнить сведения о них. 
Получив эту особенность, вы также узнаёте один язык на ваш выбор, на котором говорят ваши заклятые враги, если они вообще говорят. 
Вы можете выбрать ещё по одному заклятому врагу и связанному с ним языку на 6-м и 14-м уровнях. Старайтесь, чтобы ваш выбор при повышении уровня отражал столкновения с чудовищами в ваших приключениях.`;

	return <Layout title={'Главная'}>Тут пока ничего нет :(</Layout>;
};

export default IndexPage;
